/* Sidebar styling */

.img-avatar {
  margin: 0 auto;
}

.sidenav {
  background-color: var(--sidenav-bg);
}

.sidenav .name {
  color: var(--white-header);
}

.sidenav .email {
  color: var(--white-subheader);
}

.subheader {
  color: var(--white-header) !important;
  font-family: Muli;
  font-size: 12px !important;
  text-transform: uppercase;
}

.sidenav-item {
  color: var(--white-subheader) !important;
}

.sidenav li > a > i,
.sidenav li > a > [class^="mdi-"],
.sidenav li > a li > a > [class*="mdi-"],
.sidenav li > a > i.material-icons {
  color: var(--white-subheader);
}

.user-view {
  padding: 5px !important;
}

.settings-icon {
  float: left;
}

.logout-icon {
  float: right;
}
.logout-icon button {
  color: #fff;
  background: #3e5272;
  margin-bottom: 18px;
}

.logout-icon button:hover {
  background: #4a6188;
}

.logout-icon button:focus {
  background: #4a6188;
}

.sidenav-configure {
  padding: 15px 20px 0px;
}

nav .brand-logo {
  color: var(--black-header);
  font-size: 26px;
  font-family: Open Sans;
}

nav {
  background-color: var(--background-color);
}

.active,
.active i {
  color: var(--white-header) !important;
}

.active i {
  display: inline-block;
  position: relative;
}

.active i:after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--white-header);
  width: 70%;
  bottom: 0px;
  margin-bottom: 8px;
  left: 12%;
  animation: fadeInLeft;
  animation-duration: 0.2s;
}

/* End sidebar styling */

/* Spinner styling */

.no-padding {
  padding: 0 !important;
}

.spinner {
  margin: 0 auto;
  margin-top: 30%;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #2c3a51;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* End spinner styling */

/* Footer styling */

.container {
  min-height: 90vh;
}

.footer {
  color: var(--grey-header);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 10px;
}

/* End footer styling */

/* Data table styling */

input:focus {
  border-bottom: 1px solid var(--sidenav-bg) !important;
  box-shadow: 0 1px 0 0 var(--sidenav-bg) !important;
}

.MuiInput-underline:before {
  display: none !important;
}

.MuiInput-underline:after {
  display: none !important;
}

.MuiTypography-h6 {
  font-family: Muli !important;
  font-size: 17px;
  color: var(--sidenav-bg);
  text-transform: uppercase;
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
}

.MuiTable-root {
  font-family: system-ui !important;
}

.MTableToolbar-title-9 {
  width: 200px;
}

tr {
  border-bottom: 0;
}

.MuiTableCell-root {
  border-bottom: 0 !important;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}

.modal-create-btn {
  margin-right: 10px !important;
  background-color: #3e5272;
}

.modal-create-btn:hover {
  background-color: #3e5272;
}

.dropdown-content li > a,
.dropdown-content li > span {
  color: #000;
}

input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type="text"]:not(.browser-default),
input.valid[type="text"]:not(.browser-default):focus,
input.valid[type="password"]:not(.browser-default),
input.valid[type="password"]:not(.browser-default):focus,
input.valid[type="email"]:not(.browser-default),
input.valid[type="email"]:not(.browser-default):focus,
input.valid[type="url"]:not(.browser-default),
input.valid[type="url"]:not(.browser-default):focus,
input.valid[type="time"]:not(.browser-default),
input.valid[type="time"]:not(.browser-default):focus,
input.valid[type="date"]:not(.browser-default),
input.valid[type="date"]:not(.browser-default):focus,
input.valid[type="datetime"]:not(.browser-default),
input.valid[type="datetime"]:not(.browser-default):focus,
input.valid[type="datetime-local"]:not(.browser-default),
input.valid[type="datetime-local"]:not(.browser-default):focus,
input.valid[type="tel"]:not(.browser-default),
input.valid[type="tel"]:not(.browser-default):focus,
input.valid[type="number"]:not(.browser-default),
input.valid[type="number"]:not(.browser-default):focus,
input.valid[type="search"]:not(.browser-default),
input.valid[type="search"]:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid var(--sidenav-bg);
  box-shadow: 0 1px 0 0 var(--sidenav-bg);
}

/* End table styling */

/* 404 Not Found styling */

.notfound-404 {
  font-size: 116px;
  font-weight: 200;
  color: var(--white-subheader);
  text-transform: uppercase;
}

.notfound h2 {
  font-size: 26px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--black-header);
}

.notfound p {
  font-size: 16px;
  font-weight: 200;
  margin-bottom: 50px;
  color: var(--black-header);
}

@media only screen and (max-width: 600px) {
  .notfound .notfound-404 {
    position: relative;
    height: 166px;
  }

  .notfound-404 {
    font-size: 122px;
  }

  .notfound h2 {
    font-size: 20px;
  }
}

/* End 404 Not Found styling*/

/* Material table */

.mat-table {
  max-width: 100%;
}

button:focus {
  background-color: darkgrey;
}

/* End Material table styling */

/* DarkMode */

.switch label input[type="checkbox"]:checked + .lever {
  background-color: grey;
}

.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #cfcfcf;
}

.lever {
  margin-bottom: 17px !important;
}

.checkbox-darkmode[type="checkbox"].filled-in:checked + label:after {
  border: 2px solid #ff9800;
  background-color: #ff9800;
}

/* End DarkMode styling  */
