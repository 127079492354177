.blockquote-success {
  border-left: 5px solid var(--success-color);
}

.btn-modal-close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.production-table i {
  color: var(--sidenav-bg);
}

.selectable-bg::selection {
  background-color: var(--sidenav-bg);
  color: white;
}

.rotate-toggle {
  cursor: pointer;
  font-weight: bold;
  margin-left: 0.5rem;
  transform: rotate(360deg);
  transition: transform 1s;
}
.rotate-toggle:active {
  transform: rotate(0deg);
  transition: 0s;
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
