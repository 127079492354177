/* DetailedSandbox styling */

.details-card {
	border-radius: 10px;
	height: 100%;
}

.card-info {
	width: 100%;
}

.details-card p {
	font-weight: 300;
	display: inline;
}

.details-card .s4 {
	text-align: center;
}

.col-chart-detail {
	margin-top: -167px;
}
.chart-click {
	cursor: pointer;
}

.col-chart-detail canvas {
	height: 399px !important;
}

.delete-icon {
	position: relative;
	top: 2.5px;
	right: 2px;
	font-size: 15px;
}

/* End DetailSandbox styling */

/* New Sandbox Modal */

.modal-add label {
	color: #000 !important;
}

/* End New Sandbox Modal */

/* Media queries */

@media only screen and (max-width: 600px) {
	.s6 {
		width: 100% !important;
	}
	.col-chart-detail {
		margin-top: 20px;
	}

	.overview-container canvas {
		margin-top: 0px;
	}

	.overview-container .s6:last-child {
		margin-top: 200px;
	}

	.overview-container .chart-overview .s6:first-child {
		margin-top: -358px;
	}

	.overview-container .chart-overview .s6:last-child {
		margin-top: -41px;
	}
}

/* End media queries */

/* Errors page */

.errors-table tr td {
	overflow: auto;
	font-size: 13px !important;
}

/* End errors styling */
