.card-underlined {
	border-bottom: 5px solid var(--sidenav-bg);
}

.card-underlined-danger {
	border-bottom: 5px solid var(--danger-color);
}

.card-panel {
	border-radius: 10px;
}

.card-panel h6 {
	opacity: 0.6;
	margin: 0;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.card-panel.card-inverted h6 {
	opacity: 0.8;
	color: #fff;
}

.card-text-blue {
	color: var(--sidenav-bg);
}

.card-chips {
	margin-top: 36.62px;
}

.card-error {
	margin-top: 51.62px;
}

.blockquote {
	color: var(--sidenav-bg);
	border-left-color: var(--sidenav-bg);
}

.blockquote-danger {
	color: var(--sidenav-bg);
	border-left-color: var(--danger-color);
	overflow: auto;
}

.card-text-white {
	color: #fff;
}

.card-text-danger {
	color: var(--danger-color);
}

.text-muted {
	opacity: 0.6;
}

.card-inverted {
	background-color: var(--sidenav-bg);
}

.error-count {
	margin: 16px 0;
	font-size: 300%;
}

.chip-success {
	background-color: var(--success-color);
	color: #fff;
}

.chip-danger {
	background-color: var(--danger-color);
	color: #fff;
}

.chip-blue {
	background-color: var(--sidenav-bg);
	color: #fff;
}

.mb0 {
	margin-bottom: 0px;
}

.mt0 {
	margin-top: 0px;
}

.mr0 {
	margin-right: 0px;
}

.ml0 {
	margin-left: 0px;
}

.pb0 {
	padding-bottom: 0px;
}

.pt0 {
	padding-top: 0px;
}

.d-flex {
	display: flex;
}

.align-items-stretch {
	align-items: stretch;
}

.baseline-flex {
	display: flex;
	align-items: baseline;
	flex-direction: column;
}

.mw-100 {
	min-width: 100%;
}
