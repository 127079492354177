/* General styling */
:root {
  --sidenav-bg: #2c3a51;
  --white-header: #d1d2d4;
  --white-subheader: #9b9fa2;
  --black-header: #1d1d21;
  --grey-header: #a7a6ae;
  --background-color: #f4f5f8;
  --danger-color: #cf5f58;
  --success-color: #5ea77c;
  --info-color: #6d6875;
  --darkmode-white: #ececec;
}

/* Hide scrollbars */
body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body.light-mode {
  background-color: #f4f5f8;

  .menu-icon {
    color: #2c3a51;
  }
}

body.dark-mode {
  background-color: #333;
  nav {
    background-color: #333;
  }
  .card {
    background-color: black;
    color: var(--darkmode-white);
  }
  input[type='text']:disabled {
    color: var(--darkmode-white);
    border-bottom: 1px dotted var(--darkmode-white);
  }
  .breadcrumb {
    color: #e1e1e1;
  }
  .breadcrumb:last-child {
    color: #e1e1e1;
  }
  .menu-header {
    color: #f7f7f7;
  }
  .menu-subheader {
    color: #cfcfcf;
  }
  canvas {
    background-color: black;
  }
  .sidenav {
    background-color: #222;
  }
  .container-submenu {
    color: #f7f7f7;
  }
  .container-active:after {
    border-bottom: 2px solid #cfcfcf;
  }
  .MuiPaper-root {
    color: var(--darkmode-white);
    background-color: black;
  }
  .MuiIcon-fontSizeSmall {
    color: var(--darkmode-white);
  }
  .MuiTableCell-head {
    color: var(--darkmode-white);
    background-color: black;
  }
  .MuiTableSortLabel-root {
    &:hover {
      color: var(--darkmode-white);
    }
  }
  .MuiTypography-h6 {
    color: var(--darkmode-white);
  }
  .MuiInputBase-root {
    color: var(--darkmode-white);
  }
  .MuiTypography-caption {
    color: var(--darkmode-white);
  }
  .MuiIconButton-label {
    color: var(--darkmode-white);
  }
  .MuiTableCell-body {
    color: var(--darkmode-white);
  }
  .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #333;
  }
  .modal-add {
    label {
      color: var(--darkmode-white) !important;
    }
    input {
      color: var(--darkmode-white);
    }
  }
  .modal-create-btn {
    background-color: #666;
  }
  .card-shadow:after {
    box-shadow: none;
  }
  .dot1,
  .dot2 {
    background-color: var(--darkmode-white);
  }
  .notfound-404 {
    color: #cfcfcf;
  }
  .notfound h2 {
    color: #efefef;
  }
  .notfound p {
    color: var(--darkmode-white);
  }
  .card-panel {
    background-color: black;
    color: var(--darkmode-white);
    border-radius: 10px;
  }
  .card-text-blue {
    color: var(--darkmode-white);
  }
  .blockquote {
    color: var(--darkmode-white);
  }
  .blockquote-danger {
    color: var(--darkmode-white);
  }
  .modal-content {
    background-color: #333;
    color: #fff;
  }
  .modal {
    background-color: #333;
    color: #fff;
  }
  .modal-footer {
    background-color: #333;
  }
  .modal-close {
    color: #fff;
  }

  .modal-close:active {
    background-color: #333;
    color: #fff;
  }

  .modal-close:focus {
    background-color: #333;
    color: #fff;
  }

  .production-table i {
    color: var(--success-color);
  }

  .send-email-to {
    color: rgba(0, 0, 0, 0.38);
  }
}

.send-email-to {
  color: rgba(0, 0, 0, 0.123);
}

.modal-close:active {
  background-color: #fff;
}

.modal-close:focus {
  background-color: #fff;
}

.modal {
  border-radius: 10px;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-between {
  align-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

.flex-grow-8 {
  flex-grow: 8;
}

.w-100 {
  width: 100%;
}

nav,
.container,
footer {
  padding-left: 150px;
}

@media only screen and (max-width: 992px) {
  nav,
  .container,
  footer {
    padding-left: 0;
  }
}

.container {
  font-family: Muli;
}

.main {
  width: 50%;
}

h4 {
  font-weight: 300;
}

.container-submenu {
  color: var(--sidenav-bg);
}

.container-active {
  display: inline-block;
  position: relative;
}

.container-active:after {
  position: absolute;
  content: '';
  border-bottom: 2px solid var(--sidenav-bg);
  width: 30%;
  bottom: 0;
  margin-bottom: -3px;
  left: 33%;
  animation: fadeInLeft;
  animation-duration: 0.3s;
}

.breadcrumb {
  font-size: 14px;
  color: #95949e;
}

.breadcrumb:before {
  font-size: 14px;
  color: #95949e;
}
.breadcrumb:last-child {
  color: #95949e;
  text-transform: capitalize;
}

.chip {
  height: 100%;
}

.chip-info {
  background-color: var(--info-color);
  color: #fff;
}

.chip-verified {
  background-color: var(--success-color);
  color: #fff;
}

.chip-provision {
  background-color: var(--white-header);
  color: var(--info-color);
}

.chip-update {
  background-color: var(--info-color);
  color: #fff;
}

button.chip.chip-update.chip-update {
  position: relative;
  top: -7px;
  border: none;
  cursor: pointer;
  font-family: Muli;
}
button.chip.chip-update.chip-update:focus {
  background-color: var(--info-color);
}
button.chip.chip-update.chip-update:active {
  background-color: var(--info-color);
}

button.chip.chip-danger.chip-delete {
  position: relative;
  top: -7px;
  border: none;
  cursor: pointer;
  font-family: Muli;
}
button.chip.chip-danger.chip-delete:focus {
  background-color: var(--danger-color);
}
button.chip.chip-danger.chip-delete:active {
  background-color: var(--danger-color);
}

button.chip.chip-provision.chip-provision {
  position: relative;
  top: -7px;
  border: none;
  cursor: pointer;
  font-family: Muli;
}

button.chip.chip-provision.chip-provision:focus {
  background-color: var(--white-header);
  color: var(--info-color);
}
button.chip.chip-provision.chip-provision:active {
  background-color: var(--white-header);
  color: var(--info-color);
}

button.chip.chip-verified.chip-verified {
  position: relative;
  top: -7px;
  border: none;
  cursor: pointer;
  font-family: Muli;
}
button.chip.chip-verified.chip-verified:focus {
  background-color: var(--success-color);
}
button.chip.chip-verified.chip-verified:active {
  background-color: var(--success-color);
}

.theme-chip-button {
  border: none;
  cursor: pointer;
  font-family: Muli;
  background-color: var(--sidenav-bg);
  color: #fff;
  height: 32px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.theme-chip-button:active {
  background-color: var(--sidenav-bg);
}

.theme-chip-button-grey {
  border: none;
  cursor: pointer;
  font-family: Muli;
  background-color: var(--white-header);
  color: var(--info-color);
  height: 32px;
}

.theme-chip-button-grey:active {
  background-color: var(--white-header);
  color: var(--info-color);
}

.menu-header {
  color: #54555e;
  display: inline-block;
  margin-right: 20px;
}

.menu-subheader {
  color: #a7a6ae;
}

/* End general styling */

/* Chart styling */

canvas {
  width: 99.999% !important;
  background-color: white;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.chart-overview .col {
  margin-top: -37px;
  z-index: 999;
}

/* End chart styling */

/* Card styling */

.card {
  color: var(--sidenav-bg);
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  max-width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100px;
}

.card-shadow:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 5px -1px #999;
  z-index: -1;
}

.card h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.card h2 {
  letter-spacing: 1px;
  margin: 10px 0;
  font-size: 200%;
}

.card-info {
  padding: 30px;
  position: relative;
}

.card-table {
  padding: 0px;
  position: relative;
}

/* End card styling */

/* Media queries */

@media only screen and (max-width: 600px) {
  .MuiTypography-h6 {
    font-size: 12px !important;
  }

  h6 {
    font-size: 12px !important;
  }

  .submenu .col {
    margin-right: 10%;
  }

  .MTableToolbar-searchField-52 {
    padding-left: 0 !important;
    min-width: 0 !important;
    max-width: 180px !important;
  }
}

/* End media queries */
